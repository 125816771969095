import APIService from './APIService';

export default {
  getCountUserPendenciasNotDone() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`caixa-de-entrada/get-count-user-pendencias-not-done`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
  getUserPendencias() {
    return new Promise((resolve, reject) => {
      APIService.apiCall().get(`caixa-de-entrada/get-user-pendencias`)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }, 
}