var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-inbox","title":"Caixa de Entrada","inline":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-1",attrs:{"cols":"6"}},[_c('select-company',{ref:"refSelectCompany",attrs:{"changeCompany":false,"clearable":true},on:{"selected":_vm.handleCompanySelected,"companySelected":function($event){return _vm.changeSelectedCompany($event)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('input-month',{staticClass:"mb-0 pt-5",attrs:{"label":"Competência de*","hide-details":"","selectedMonth":_vm.competencia},on:{"monthSelected":_vm.setDataDe}})],1)],1),_c('v-tabs',{staticClass:"mt-5",attrs:{"center-active":"","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-badge',{attrs:{"value":_vm.notificacoesPendentes.length > 0,"color":"warning","content":_vm.notificacoesPendentes.length,"right":""}},[_vm._v(" Notificações pendentes ")])],1),_c('v-tab',[_vm._v(" Notificações concluídas ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.notificacoesPendentesHeader,"items":_vm.notificacoesPendentes,"options":{ itemsPerPage: -1 },"sort-by":['created_at'],"sort-desc":[true],"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.notification",fn:function(ref){
var item = ref.item;
return [_c('notifications-template',{attrs:{"tipo_pendencia":item.tipo_pendencia,"notificationData":item}})]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateBR")(item.deadline))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.created_at))+" ")]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.notificacoesConcluidasHeader,"items":_vm.notificacoesConcluidas,"options":{ itemsPerPage: -1 },"sort-by":['updated_at'],"sort-desc":[true],"headerProps":{ sortByText: 'Ordenar Por' },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},scopedSlots:_vm._u([{key:"item.notification",fn:function(ref){
var item = ref.item;
return [_c('notifications-template',{attrs:{"tipo_pendencia":item.tipo_pendencia,"notificationData":item}})]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user != null ? item.user.name : 'Administrador')+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateBR")(item.deadline))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.updated_at))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading,"opacity":0.85}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }